import { IDeliveryRegion } from "../interfaces/UIInterfaces"

export const deliveryRegions: IDeliveryRegion[] = [
    {name: ' Жуковский при заказе от 500 р.', price: '50 р.'},
    {name: 'Раменское при заказе от 2000р.', price: '150-250 р. (В зависимости от удаленности)'},
    {name: 'Дергаево при заказе от 2000р.', price: '250 р.'},
    {name: 'Новое Село при заказе от 1000р.', price: '300 р.'},
    {name: 'Пос.Первомайка при заказе от 1500р.', price: '400 р.'},
    {name: 'Быково р/с при заказе от 2000р.', price: '200 р.'},
    {name: 'Быково н/с при заказе от 500р.', price: '100 р.'},
    {name: 'Удельная р/с при заказе от 2000р.', price: '200 р.'},
    {name: 'Удельная н/с при заказе от 1000р.', price: '120 р.'},
    {name: 'Ильинский р/с при заказе от 2000р.', price: '200-250 р. (В зависимости от удаленности)'},
    {name: 'Кратово р/с при заказе от 2000р.', price: '200 р.'},
    {name: 'Малаховка р/с при заказеот 2000р.', price: '200-250 р. (В зависимости от удаленности)'},
    {name: 'Малаховка н/с при заказе от 1000р.', price: '150-250 р. (В зависимости от удаленности)'},
    {name: 'Пос.Дружба при заказе от 2500р.', price: '400 р.'},
    {name: 'Родники при заказе от 2000р.', price: '200 р.'},
    {name: 'Вялки при заказе от 2000р.', price: '300 р.'},
    {name: 'Хрипань при заказе от 2000р.', price: '250 р.'},
    {name: 'Поповка при заказе от 2000р.', price: '300 р.'},
    {name: 'Дониское при заказе шоссе от 2000р.', price: '250 р.'},
    {name: 'Игумново при заказе от 2000р.', price: '300 р.'},
    {name: 'Донино при заказе от 2000р.', price: '320 р.'},
    {name: 'Клишева при заказе от 2000р.', price: '300 р.'},
    {name: 'Дубовая роща при заказе от 2000р.', price: '300 р.'},
    {name: 'Малаховка ж/с', price: '120 р.'},
    {name: 'Пос.Тельмана при заказе от 1500р.', price: '100 р.'},
    {name: 'Михайловская Слобода при заказе от 1500р.', price: '200 р.'},
    {name: 'Белый берег при заказе от 1500р.', price: '150 р.'},
    {name: 'Каменное Тяжино при заказе от 2000р.', price: '200 р.'},
    {name: 'Дурниха при заказе от 2000р.', price: '180 р.'},
    {name: 'Синьково при заказе от 2000р.', price: '200 р.'},
    {name: 'Островцы при заказе от 1500р.', price: '150 р.'},
    {name: 'Октябрьский при заказе от 1500р.', price: '200 р.'},
    {name: 'Загорного при заказе от 2000р.', price: '350 р.'},
    {name: 'Томилино Парк при заказе от 2000р.', price: '500 р.'},
    {name: 'Котельники при заказе от 5000р.', price: '650 р.'},
    {name: 'Ильинский ж/с при заказе от 500 р.', price: '50 р.'},
    {name: 'Кратово ж/с при заказе от 500р.', price: '50 р.'}
].sort((a,b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0))